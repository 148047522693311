<!--
 * @Descripttion: 全部商品页面组件
 * @Author: congz
 * @Date: 2020-06-04 11:22:40
 * @LastEditors: congz
 * @LastEditTime: 2020-08-12 20:56:01
--> 

<template>
  <div class="goods" id="goods" name="goods">
    <!-- 面包屑 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item to="/">首页</el-breadcrumb-item> -->
        <el-breadcrumb-item>全部商品</el-breadcrumb-item>
        <el-breadcrumb-item v-if="keyword">搜索</el-breadcrumb-item>
        <el-breadcrumb-item v-else-if="activeName !== '0'">
			<el-select v-for="(item, index) in selectCateArr" v-model="selectCateArr[index]" size="mini" :key="index" class="select-cate" clearable @clear="clearCate(index)" @change="goCate">
				<template v-if="getCategory.length > 0">
					<el-option v-for="(item1, index1) in selectCateDataArr[index]" :label="item1.name" :value="item1.id" :key="index1"></el-option>
				</template>
			</el-select>
		</el-breadcrumb-item>
		<el-breadcrumb-item v-if="brandID && brandName"><el-tag type="info" size="small" closable @close="closeBrand">{{ brandName }}</el-tag></el-breadcrumb-item>
        <el-breadcrumb-item v-if="keyword">{{keyword}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 面包屑END -->

    <!-- 分类标签 -->
    <div class="nav">
      <div class="product-nav">
        <!-- <div class="title">品类</div>
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane
            v-for="item in getCategory"
            :key="item.id"
            :label="item.name"
            :name="''+item.id"
          />
        </el-tabs> -->
		<el-row v-if="subCateArr.length > 0" class="el-row">
			<el-col :span="2">&nbsp;&nbsp;&nbsp;品类</el-col>
			<el-col :span="22">
				<router-link v-for="(it, ind) in subCateArr" :to="{ path: '/goods', query: {categoryID:it.id} }" :key="ind">{{ it.name }}</router-link>
			</el-col>
		</el-row>
		<el-row v-else-if="getCategory.length > 0 && categoryID.length === 0" class="el-row">
			<el-col :span="2">&nbsp;&nbsp;&nbsp;品类</el-col>
			<el-col :span="22">
				<router-link v-for="(it, ind) in getCategory" :to="{ path: '/goods', query: {categoryID:it.id} }" :key="ind">{{ it.name }}</router-link>
			</el-col>
		</el-row>
		<el-row v-if="allBrandArr.length > 0 && !brandID" class="el-row">
			<el-col :span="2">&nbsp;&nbsp;&nbsp;品牌</el-col>
			<el-col :span="22">
				<router-link v-for="(it, ind) in allBrandArr" :to="{ path: '/goods', query: {categoryID: activeName, brandID:it.id, brandName: it.name} }" :key="ind">{{ it.name }}</router-link>
			</el-col>
		</el-row>
      </div>
    </div>
    <!-- 分类标签END -->

    <!-- 主要内容区 -->
    <div class="main">
      <div class="list">
        <MyList :list="product" v-if="!loading && product.length>0"></MyList>
        <div v-else-if="loading" class="loading-product" style="font-size: 32px">
			<i class="el-icon-loading"></i>
		</div>
		<div v-else class="none-product">
			抱歉没有找到相关的商品，请看看其他的商品
		</div>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="limit"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 分页END -->
    </div>
    <!-- 主要内容区END -->
  </div>
</template>
<script>
import * as productAPI from '@/api/products'
import { mapGetters } from 'vuex'
// import * as categoryAPI from '@/api/categories'
import categoryAPI from '@/api/category.js'
import axios from 'axios'

export default {
  data() {
    return {
      categoryList: [], //分类列表
      categoryID: [], // 分类id
		brandID: 0,
		brandName: '',
      product: '', // 商品列表
      productList: '',
      total: 0, // 商品总量
      // pageSize: 15, // 每页显示的商品数量
      currentPage: 1, //当前页码
      activeName: '0', // 分类列表当前选中的id
      keyword: '', // 搜索条件
      page: 1,
      limit: 15,
		selectCateArr: [],
		selectCateDataArr: [],
		subCateArr: [],
		allBrandArr: [],
		loading: true
    }
  },
	computed: {
		...mapGetters(['getCategory'])
	},
  created() {
    // 获取分类列表
    // this.getCategory()
  },
  activated() {
	// this.activeName = '-1' // 初始化分类列表当前选中的id为-1
    this.total = 0 // 初始化商品总量为0
    this.currentPage = 1 //初始化当前页码为1
    // 如果路由没有传递参数，默认为显示全部商品
    if (Object.keys(this.$route.query).length == 0) {
      this.categoryID = []
      this.activeName = '0'
		this.getData()
      return
    }
	if (this.$route.query.brandID != undefined) {
		this.brandID = this.$route.query.brandID
	}
	if (this.$route.query.brandName != undefined) {
		this.brandName = this.$route.query.brandName
	}
	// 如果路由传递了search，则为搜索，显示对应的分类商品
	if (this.$route.query.search != undefined) {
		this.keyword = this.$route.query.search
		this.getProductBySearch()
	} else {
		this.keyword = ''
	}
	this.changeSearchStr()
    // 如果路由传递了categoryID，则显示对应的分类商品
    if (this.$route.query.categoryID != undefined) {
      this.categoryID = this.$route.query.categoryID
		this.selectCateArr = []
		this.selectCateDataArr = []
      if (this.categoryID.length > 0) {
        this.activeName = '' + this.categoryID
		this.setCateBreadcrumb()
      }
		this.getData()
      return
    }
  },
  watch: {
    // 监听点击了哪个分类标签，通过修改分类id，响应相应的商品
    // activeName: function(val) {
    //   if (val == 0) {
    //     this.categoryID = []
    //   }
    //   if (val > 0) {
    //     this.categoryID = [Number(val)]
    //   }
    //   // 初始化商品总量和当前页码
    //   this.total = 0
    //   this.currentPage = 1
    //   // 更新地址栏链接，方便刷新页面可以回到原来的页面
    //   this.$router.push({
    //     path: '/goods',
    //     query: { categoryID: this.categoryID }
    //   })
    // },
	getCategory: function(val) {
		if (val.length > 0) {
			this.setCateBreadcrumb()
		}
	}
    // 监听搜索条件，响应相应的商品
  //   keyword: function(val) {
		// console.log(val)
  //     if (val != '') {
  //       this.getProductBySearch(val)
  //     }
  //   },
    // 监听分类id，响应相应的商品
	// categoryID: function() {
	// 	this.getData()
	// 	this.search = ''
 //    },
    // 监听路由变化，更新路由传递了搜索条件
    // $route: function(val) {
    //   if (val.path == '/goods') {
    //     if (val.query.search != undefined) {
    //       this.activeName = '0'
    //       this.currentPage = 1
    //       this.total = 0
    //       this.keyword = val.query.search
    //     }
    //   }
    // }
  },
  methods: {
	changeSearchStr() {
		this.$emit('changeSearchStr', this.keyword)
	},
    // 返回顶部
    backtop() {
      const timer = setInterval(function() {
        const top =
          document.documentElement.scrollTop || document.body.scrollTop
        const speed = Math.floor(-top / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          top + speed

        if (top === 0) {
          clearInterval(timer)
        }
      }, 20)
    },
	closeBrand() {
		if (parseInt(this.activeName) > 0) {
			this.$router.push({
				path: '/goods',
				query: { categoryID: this.activeName }
			})
		} else {
			this.$router.push({
				path: '/goods'
			})
		}
	},
	goCate(categoryID) {
		this.$router.push({
			path: '/goods',
			query: { categoryID: categoryID }
		})
	},
	clearCate(selectCateArrIndex) {
		if (selectCateArrIndex > 0) {
			this.$router.push({
				path: '/goods',
				query: { categoryID: this.selectCateArr[selectCateArrIndex - 1] }
			})
		} else {
			this.$router.push({
				path: '/goods'
			})
		}
	},
	setCateBreadcrumb() {
		this.getCategory.map(item => {
			const cateOne = this.getCategory.map(it => {
				return { id: it.id, name: it.name }
			})
			if (parseInt(item.id) === parseInt(this.categoryID)) {
				this.selectCateArr = [parseInt(this.categoryID)]
				this.selectCateDataArr = [cateOne]
				if (item.children && item.children.length > 0) {
					this.subCateArr = item.children.map(it => {
						return { id: it.id, name: it.name }
					})
				}
				return
			}
			if (item.children && item.children.length > 0) {
				const cateTwo = item.children.map(it => {
					return { id: it.id, name: it.name }
				})
				item.children.map(item1 => {
					if (parseInt(item1.id) === parseInt(this.categoryID)) {
						this.selectCateArr = [parseInt(item.id), parseInt(this.categoryID)]
						this.selectCateDataArr = [cateOne, cateTwo]
						if (item1.children && item1.children.length > 0) {
							this.subCateArr = item1.children.map(it => {
								return { id: it.id, name: it.name }
							})
						}
						return
					}
					if (item1.children && item1.children.length > 0) {
						const cateThree = item1.children.map(it => {
							return { id: it.id, name: it.name }
						})
						item1.children.map(item2 => {
							if (parseInt(item2.id) === parseInt(this.categoryID)) {
								this.selectCateArr = [parseInt(item.id), parseInt(item1.id), parseInt(this.categoryID)]
								this.selectCateDataArr = [cateOne, cateTwo, cateThree]
								if (item2.children && item2.children.length > 0) {
									this.subCateArr = item2.children.map(it => {
										return { id: it.id, name: it.name }
									})
								}
								return
							}
							if (item2.children && item2.children.length > 0) {
								const cateThour = item2.children.map(it => {
									return { id: it.id, name: it.name }
								})
								item2.children.map(item3 => {
									if (parseInt(item3.id) === parseInt(this.categoryID)) {
										this.selectCateArr = [parseInt(item.id), parseInt(item1.id), parseInt(item2.id), parseInt(this.categoryID)]
										this.selectCateDataArr = [cateOne, cateTwo, cateThree, cateThour]
										if (item3.children && item3.children.length > 0) {
											this.subCateArr = item3.children.map(it => {
												return { id: it.id, name: it.name }
											})
										}
										return
									}
								})
							}
						})
					}
				})
			}
		})
	},
    handleCurrentChange(val) {
      this.page = val // val 页面
      this.getData()
    },
    // 向后端请求分类列表数据
  //   getCategory() {
  //     // categoryAPI
  //     //   .listCategories()
  //     //   .then(res => {
  //     //     if (res.status === 200) {
  //     //       const val = {
  //     //         category_id: 0,
  //     //         category_name: '全部'
  //     //       }
  //     //       const cate = res.data
  //     //       cate.unshift(val)
  //     //       this.categoryList = cate
  //     //     } else {
  //     //       this.notifyError('获取分类失败', res.msg)
  //     //     }
  //     //   })
  //     //   .catch(err => {
  //     //     this.notifyError('获取分类失败', err)
  //     //   })
		// categoryAPI.getCategoryList({ page: 1, limit: 14 }).then(res => {
		// 	if (res.status_code == 200) {
		// 		const val = {
		// 			id: 0,
		// 			name: '全部'
		// 		}
		// 		const cate = res.data.data
		// 		cate.unshift(val)
		// 		this.categoryList = cate
		// 	}
		// }).catch(err => {
		// 	// console.log('获取分类数据失败')
		// })
  //   },
    // 向后端请求全部商品或分类商品数据
    getData() {
		this.loading = true
      // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
      if (this.categoryID.length === 0) {
        productAPI
          .listProducts({ status: 'normal', brand_id: this.brandID, page: this.page, limit: this.limit })
          .then(res => {
			this.loading = false
            if (res.status_code === 200) {
              this.product = res.data.data
              this.total = res.data.total
				this.catchAllBrand()
            } else {
              this.notifyError('获取商品失败', res.message)
            }
          })
          .catch(err => {
            this.notifyError('获取商品失败', err)
          })
      } else {
		this.loading = true
        // let id = this.categoryID[0]
        productAPI
          .listProducts({ keywork: this.keyword, category_id: this.activeName, brand_id: this.brandID, status: 'normal', page: this.start, limit: this.limit })
          .then(res => {
			this.loading = false
            if (res.status_code === 200) {
				this.product = res.data.data
				this.total = res.data.total
				this.catchAllBrand()
            } else {
              this.notifyError('获取分类商品失败', res.message)
            }
          })
          .catch(err => {
            this.notifyError('获取商品失败', err)
          })
      }
    },
	catchAllBrand() {
		this.allBrandArr = []
		this.product.map(item => {
			if (item.brand) {
				let flag = 1
				this.allBrandArr.map(it => {
					if (it.id === item.brand.id) {
						flag = 0
						return
					}
				})
				if (flag) {
					this.allBrandArr.push({ id: item.brand.id, name: item.brand.name })
				}
			}
		})
	},
    // 通过搜索条件向后端请求商品数据
    getProductBySearch() {
      var form = {
        keyword: this.keyword
      }
	this.loading = true
      productAPI
        .listProducts(form)
        .then(res => {
			this.loading = false
          if (res.status_code === 200) {
            this.product = res.data.data
          } else {
            this.notifyError('搜索失败', res.message)
          }
        })
        .catch(err => {
          this.notifyError('搜索失败', err)
        })
    }
  }
}
</script>

<style scoped>
.goods {
  background-color: #f5f5f5;
}
/* 面包屑CSS */
.el-tabs--card .el-tabs__header {
  border-bottom: none;
}
.goods .breadcrumb {
  height: 50px;
  background-color: #f5f5f5;
  margin-top: 10px;
}
.goods .breadcrumb .el-breadcrumb {
  width: 1225px;
  line-height: 30px;
  font-size: 16px;
  margin: 0 auto;
}
.breadcrumb .select-cate {
	position: relative;
	margin-right: 28px;
}
.breadcrumb .select-cate:last-child {
	margin-right: 0;
}
.breadcrumb .select-cate::after {
	content: ">";
	display: block;
	position: absolute;
	right: -20px;
	top: 0px;
}
.breadcrumb .select-cate:last-child::after {
	display: none;
}
/* 面包屑CSS END */

/* 分类标签CSS */
.goods .nav {
  background-color: #f5f5f5;
}
.goods .nav .product-nav {
  width: 1225px;
  /* height: 40px; */
  line-height: 40px;
  margin: 0 auto;
}
.nav .product-nav .title {
  width: 50px;
  font-size: 16px;
  font-weight: 700;
  float: left;
}
.el-row {
	border: 1px solid #ddd;
	font-size: 14px;
	color: #666666;
}
.el-row:first-child {
	border-bottom: none;
}
.el-col-2 {
	background: #eee;
}
.el-col-22{
	background: #fff;
	line-height: 24px;
	padding: 8px 8px 8px 0px;
}
.el-col-22 a{
	color: #555;
	margin-left: 20px;
	display: inline-block;
}
.el-col-22 a:hover{
	text-decoration: underline;
	color: #ff6700;
}
/* .el-col-22 a:first-child{
	margin-left: 0;
} */
/* 分类标签CSS END */

/* 主要内容区CSS */
.goods .main {
  margin: 0 auto;
  max-width: 1225px;
}
.goods .main .list {
  /* min-height: 650px; */
  padding-top: 14.5px;
  overflow: auto;
}
.goods .main .pagination {
  height: 50px;
  text-align: center;
}
.goods .main .none-product {
	background: url(../assets/imgs/empty.png) no-repeat 150px -30px;
	background-size: contain;
	color: #666;
	padding: 100px 0 125px 450px;
	text-align: left;
}
.goods .main .loading-product{
	text-align: center;
	color: #666;
	padding: 50px 0 65px;
}
/* 主要内容区CSS END */
</style>